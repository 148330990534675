<template>
  <v-alert
    prominent
    type="success"
    class="mt-2 headline text-center"
  >
    {{ $t('multiproject.projectFinished.message') }}
  </v-alert>
</template>

<script>
export default {
  name: 'ProjectFinishedContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    message () {
      const duration = this.currentPlayer.projectDuration
      const changes = this.currentPlayer.historyWeekEndPlanned.length
      const durationText = this.$tc('multiproject.projectFinished.done', duration, { duration })
      const changesText = this.$tc('multiproject.projectFinished.changes', changes, { changes })

      return `${durationText}, ${changesText}`
    },
  },
}
</script>
